import React from "react"
import { Link } from "gatsby"
import { SectionTitle, ResponsiveImage, Button } from "@hemalr/react-blocks"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { primaryColor } from "../components/site-config"
import PricingColumn from "../components/pricing-column"
import Subtitle from "../components/subtitle"
import justStartingIcon from "../images/just-starting.png"
import growingIcon from "../images/growing.png"
import nailingItIcon from "../images/nailing-it.png"

const StyledDiv = styled.div`
  margin: auto;
  padding: 2rem;
  display: grid;
  grid-gap: 3rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 16rem));
  font-size: 0.95rem;
`

const StyledList = styled.ul`
  padding-left: 1rem;
  & > * {
    margin-bottom: 0.75rem;
  }
`

const StyledLinkContainer = styled.div`
  text-align: center;
  margin: 1rem;
`

const Packages = () => (
  <Layout>
    <SEO title="Accounting Packages" />

    <SectionTitle
      text="Fixed Price Packages"
      color={primaryColor}
      level="h1"
      size="3rem"
    />
    <Subtitle text="Monthly pricing that's easy, transparent and affordable" />
    <StyledDiv>
      <PricingColumn
        name="Just Starting"
        price="From $199 per month"
        icon={
          <ResponsiveImage
            src={justStartingIcon}
            maxWidth="50%"
            alt="Just starting icon"
          />
        }
      >
        Perfect for startups
        <StyledList>
          <li>First meeting with setting up forecasts and budgets</li>
          <li>Xero setup and basic subscription</li>
          <li>Annual compliance</li>
          <li>Annual tax planning</li>
          <li>
            Quarterly/Monthly Business Activity Statements Perusal and Lodgement
          </li>
          <li>Email and phone support</li>
        </StyledList>
      </PricingColumn>
      <PricingColumn
        name="Growing"
        price="From $399 per month"
        icon={
          <ResponsiveImage
            src={growingIcon}
            maxWidth="50%"
            alt="Growing icon"
          />
        }
      >
        5-year steady business
        <StyledList>
          <li>First meeting with cash flow forecasts and budgets</li>
          <li>Xero setup and subscription (if required)</li>
          <li>Annual compliance</li>
          <li>Annual tax planning</li>
          <li>
            Quarterly/Monthly Business Activity Statements Perusal and Lodgement
          </li>
          <li>Half yearly business cost review</li>
          <li>Half yearly performance review and analysis</li>
          <li>Email and phone support </li>
        </StyledList>
      </PricingColumn>
      <PricingColumn
        name="Nailing it"
        price="From $599 per month"
        icon={
          <ResponsiveImage
            src={nailingItIcon}
            maxWidth="50%"
            alt="Nailing it icon"
          />
        }
      >
        10 plus years and still growing
        <StyledList>
          <li>First meeting with cash flow forecasts and budgets</li>
          <li>Xero setup and subscription (if required)</li>
          <li>Annual compliance</li>
          <li>Annual tax planning</li>
          <li>
            Quarterly/Monthly Business Activity Statements Perusal and Lodgement
          </li>
          <li>Half yearly business cost review</li>
          <li>Half yearly performance review and analysis</li>
          <li>Staff management advice and structures</li>
          <li>Cost modeling advice for products and services</li>
          <li>Business strategic advice</li>
          <li>Email and phone support</li>
        </StyledList>
      </PricingColumn>
    </StyledDiv>
    <StyledLinkContainer>
      <Link to="/contact">
        <Button
          text="Reach out or book a time to see us"
          size="1.5rem"
          color={primaryColor}
          rounded
        />
      </Link>
    </StyledLinkContainer>
  </Layout>
)

export default Packages
