import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { primaryColor } from "./site-config"

const StyledPricing = styled.div`
  padding: auto 2rem;
`

const StyledH3 = styled.h3`
  font-size: 1.5rem;
  color: ${primaryColor};
  margin: 0 auto 1rem auto;
  text-align: center;
`
const StyledH4 = styled.h4`
  font-size: 1.25rem;
  margin: 0 auto 2rem auto;
  text-align: center;
`
const CenteredImage = styled.div`
  display: grid;
  justify-items: center;
  margin: 2rem auto;
`

const PricingColumn = ({ icon, price, name, children }) => {
  return (
    <StyledPricing>
      <CenteredImage>{icon}</CenteredImage>
      <StyledH3>{name}</StyledH3>
      <StyledH4>{price}</StyledH4>
      {children}
    </StyledPricing>
  )
}

PricingColumn.propTypes = {
  icon: PropTypes.node.isRequired,
  price: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default PricingColumn
